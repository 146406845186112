.home-search-section .mapboxgl-ctrl-geocoder a {
  text-decoration: none;
}

.home-search-section .mapboxgl-ctrl-geocoder {
  border: "none";
  border-radius: 0;
  border-right-width: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  height: 50px;
  padding: 6px 12px;
  font-size: 15px;
  line-height: 20px;
  width: 320px !important;
}

@media (max-width: 600px) {
  .home-search-section .mapboxgl-ctrl-geocoder {
    box-shadow: 0px 0px 10px #555;
    margin-bottom: 16px;
    border-radius: 4px;
    width: 280px !important;
    height: 42px;
  }
  .home-search-section .mapboxgl-ctrl-geocoder--input {
    height: 30px;
    padding: 0;
  }
}

.home-search-section .mapboxgl-ctrl-geocoder--input {
  font-size: medium;
  padding: 0px;
  line-height: 20px;
}

.home-search-section .mapboxgl-ctrl-geocoder--icon {
  display: none;
}
