html,
body,
#root {
  width: 100vw;
  height: 100%;
  scroll-behavior: smooth;
}

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  border: 0;
}

p {
  color: #555;
  font-size: 20px;
}

a {
  color: black;
  cursor: pointer;
}

@media (hover: none) {
  a {
    text-decoration: underline;
  }
}

a:hover {
  text-decoration: underline;
}

@media (max-width: 800px) {
  h1 {
    font-size: 24px;
  }
  h2 {
    font-size: 18px;
  }
  h3 {
    font-size: 14px;
  }
}

@media (max-width: 500px) {
  h1 {
    font-size: 20px;
  }
  h2 {
    font-size: 16px;
  }
  h3 {
    font-size: 14px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.mapboxgl-map {
  border-radius: 50%;
  -webkit-transform: translateZ(0);
}
